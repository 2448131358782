import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import PracticePage from './practice_page';
import CodingPracticePage from './coding_practice_page';
import SignInPage from './signin_page.jsx';
import Register from './register.jsx';
import BusinessRegister from './businessregister';
import HomePage from './home_page';
import ResultsPage from './results_page';
import BusinessSignInPage from './business-signin';
import UploadResume from './uploadresume';
import JobTitle from './jobtitle';
import GetReady from './getready';
import InterviewDone from './interviewdone';
import Interview from './interview';
import CandidateResultPublic from './candidate-result-public';
import NameEmailBusiness from './name_email';
import { auth } from './index';
import { useAuth } from './authContext';
import { signOut } from 'firebase/auth';
import './App.css';
import logo from './safirawhitefixedfinal.svg';
import hamburger from './hamburger.png';
import MyEditor from './interviewcoding';
import BusinessesPage from './BusinessesPage';
import BusinessInterview from './businessinterview';
import SignInSelectionPage from './signInSelection';
import WelcomeInterview from './WelcomeInterview';
import WelcomeBusinessInterview from './welcomeBusinessInterview';
import ResetPassword from './ResetPassword'; // New import for ResetPassword component

function App() {
  const { userLoggedIn, setCurrentUser } = useAuth();
  const [showMenu, setShowMenu] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const history = useHistory();
  let lastScrollY = window.scrollY;
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMenuOpen(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setShowMenu(false);
        setIsMenuOpen(false);
      } else {
        setShowMenu(true);
      }
      lastScrollY = window.scrollY;
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handlePracticeClick = () => {
    if (!userLoggedIn) {
      history.push('/sign-in', { state: { from: 'practice' } });
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 7000);
    }
  };

  const handleCodingPracticeClick = () => {
    if (!userLoggedIn) {
      history.push('/sign-in', { state: { from: 'coding-interview' } });
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 7000);
    }
  };

  const handleBusinessesClick = () => {
    if (!userLoggedIn) {
      history.push('/business-sign-in', { state: { from: 'businesses' } });
      setShowErrorPopup(true);
      setTimeout(() => {
        setShowErrorPopup(false);
      }, 7000);
    } else {
      history.push('/businesses');
    }
  };

  return (
    <Router>
      <div>
        <div className={`menu-bar ${showMenu ? '' : 'hide'}`}>
          <Link to="/home">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <img src={hamburger} alt="Menu" className="hamburger" onClick={toggleMenu} />
          <nav>
            <ul className={`${isMenuOpen ? 'show' : 'hide'}`}>
              {/* Replaced dropdown with a single Practice link */}
              <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn ? (
                  <Link to="/sign-in" onClick={handlePracticeClick}>Practice</Link>
                ) : (
                  <Link to="/practice">Practice</Link>
                )}
              </li>
              {/* <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn && (
                  <Link to="/sign-in" onClick={handleCodingPracticeClick}>Coding Interview</Link>
                )}
                {userLoggedIn && (
                  <Link to="/coding-interview">Coding Interview</Link>
                )}
              </li> */}
              <li onClick={() => setIsMenuOpen(false)}>
                {!userLoggedIn && (
                  <Link to="/business-sign-in" onClick={handleBusinessesClick}>Businesses</Link>
                )}
                {userLoggedIn && (
                  <Link to="/businesses">Businesses</Link>
                )}
              </li>
              {!userLoggedIn && (
                <li>
                  <Link to="/sign-in-selection" onClick={() => setIsMenuOpen(false)}>Sign In</Link>
                </li>
              )}
              {userLoggedIn && (
                <li>
                  <Link to="/home" onClick={handleLogout}>Logout</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <Switch>
          <Route path="/codinginterview">
            <MyEditor />
          </Route>
          <Route path="/sign-in">
            <SignInPage />
          </Route>
          <Route path="/uploadresume">
            <UploadResume />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/practice">
            <PracticePage />
          </Route>
          <Route path="/behavioral-interview">
            <PracticePage />
          </Route>
          <Route path="/businessinterview">
            <BusinessInterview />
          </Route>
          <Route path="/coding-interview">
            <CodingPracticePage />
          </Route>
          <Route path="/results-behavioral-interview">
            <ResultsPage />
          </Route>
          <Route path="/interviewdone">
            <InterviewDone />
          </Route>
          <Route path="/businesses">
            <BusinessesPage />
          </Route>
          <Route path="/jobtitle">
            <JobTitle />
          </Route>
          <Route path="/business-register">
            <BusinessRegister />
          </Route>
          <Route path="/business-sign-in">
            <BusinessSignInPage />
          </Route>
          <Route path="/getready">
            <GetReady />
          </Route>
          <Route path="/interview">
            <Interview />
          </Route>
          <Route path="/welcomeinterview">
            <WelcomeBusinessInterview />
          </Route>
          <Route path="/candidate-result">
            <CandidateResultPublic />
          </Route>
          <Route path="/name-and-email">
            <NameEmailBusiness />
          </Route>
          <Route path="/sign-in-selection">
            <SignInSelectionPage />
          </Route>
          <Route path="/welcome">
            <WelcomeInterview />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
          <Route path="/reset-password" component={ResetPassword} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;