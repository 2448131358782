import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './authContext';
import './candidateInfo.css';

const CandidateInfo = ({ selectedCandidate, videos, overallScore, jobTitle }) => {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(true);
  const [selectedTrait, setSelectedTrait] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoPopupVisible, setIsVideoPopupVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true); // New state to control visibility
  const { currentUser } = useAuth();

  const candidateInfoRef = useRef(null); // Ref for the aside element

  const toggleFeedbackVisibility = () => {
    setIsFeedbackVisible(!isFeedbackVisible);
  };

  const handleTraitClick = (trait) => {
    setSelectedTrait(selectedTrait === trait ? null : trait);
  };

  const handlePlayClick = (index) => {
    const videoIndex = Math.floor(index / 2); // Match odd indices to video array
    if (videos && videos[videoIndex]) {
      setVideoUrl(videos[videoIndex]);
      setIsVideoPopupVisible(true);
    }
  };

  const closeVideoPopup = () => {
    setIsVideoPopupVisible(false);
    setVideoUrl(null);
  };

  const handleClickOutside = (event) => {
    if (isVideoPopupVisible){
      closeVideoPopup();
      return;

    }

    if (candidateInfoRef.current && !candidateInfoRef.current.contains(event.target)) {
      setIsVisible(false); // Hide the component when clicking outside
    }
  };

  useEffect(() => {
    setIsVisible(true); // Reset visibility when a new candidate is selected
  }, [selectedCandidate]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const scores = selectedCandidate && selectedCandidate.scores ? selectedCandidate.scores : {};
  const traits = scores ? Object.keys(scores) : [];

  const calculateOverallScore = () => {
    const scoreValues = Object.values(scores);
    const totalScore = scoreValues.reduce((total, score) => total + score, 0);
    return Math.round(totalScore / scoreValues.length) || 0;
  };

  const shouldShowCandidateInfo = selectedCandidate && scores && Object.keys(scores).length > 0;

  const traitColors = ['#D1DCF3', '#FFD07A', '#CEED89', '#FDCFE9', '#FF8440', '#94FFFF'];


  const copyLink = () => {
    console.log(selectedCandidate.uid);
    if (selectedCandidate && selectedCandidate.uid) {
      const currentUserUid = currentUser.uid;
      const formattedJobTitle = jobTitle.replace(/\s+/g, '-');
      const link = `https://safirahiring.com/candidate-result/${currentUserUid}/${selectedCandidate.uid}/${formattedJobTitle}`;
      navigator.clipboard.writeText(link)
        .then(() => {
          console.log('Link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy link: ', err);
        });
    }
  };
  

  if (!isVisible || !selectedCandidate) return null; // Return null if the component should be hidden or no candidate is selected

  return (
    <aside className={`candidate-info ${shouldShowCandidateInfo ? 'show' : ''}`} ref={candidateInfoRef}>
      <div className="candidate-info-header">
        <h2 style={{ fontSize: '1.2em' }}>{selectedCandidate ? selectedCandidate.name : 'Candidate Name'}</h2>
        <button className="copy-link-button-candidate" onClick={copyLink} style={{ position: 'absolute', top: '10px', right: '10px' }}>
          Copy Link
        </button>
        <div className="overall-score">
          <div className="score-circle overall-circle" style={{ backgroundColor: '#FFE4C7', color: '#000', fontWeight: 'bold', width: '90px', height: '90px', fontSize: '1.5em' }}>
            {overallScore}
          </div>
          <p>Overall</p>
          <div className="horizontal-circles">
            {traits.slice(0, 6).map((trait, index) => (
              <div
                className="circle-with-word"
                key={index}
                onClick={() => handleTraitClick(trait)}
                style={{ cursor: 'pointer' }}
              >
                <div className="score-circle small-circle" style={{ backgroundColor: traitColors[index % traitColors.length], fontSize: '0.6em', border: selectedTrait === trait ? '2px solid #FF6B19' : 'none' }}>
                  {scores[trait] || '00'}
                </div>
                <p className="trait-word" style={{ fontSize: '0.7em' }}>{trait.charAt(0).toUpperCase() + trait.slice(1)}</p>
              </div>
            ))}
          </div>
          {/* Update the skills section to use selectedCandidate.skills */}
          {selectedCandidate && selectedCandidate.skills && selectedCandidate.skills.length > 0 && (
            <div className="skills-container">
              {selectedCandidate.skills.map((skill, index) => (
                <span key={index} className="skill-box">
                  {skill}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="candidate-info-feedback">
        <h3 style={{ fontSize: '1.5em' }}>Feedback</h3>
        <div className="feedback-content">
          {selectedTrait ? (
            <p><strong>{selectedTrait.charAt(0).toUpperCase() + selectedTrait.slice(1)}:</strong> {selectedCandidate && selectedCandidate.feedback ? selectedCandidate.feedback[selectedTrait] : 'No feedback available.'}</p>
          ) : (
            <p style={{ color: 'gray', textAlign: 'center' }}>Click a trait to see feedback</p>
          )}
        </div>
      </div>
      <hr />
      <div className="candidate-info-transcript">
        <h3 style={{ fontSize: '1.5em' }}>Transcript</h3>
        {selectedCandidate && selectedCandidate.transcript ? (
          selectedCandidate.transcript
            .filter(item => item.role === 'assistant' || item.role === 'user')
            .map((item, index) => (
              <div className={`bubbletemplate ${item.role === 'assistant' ? 'ai' : 'human'}`} key={index}>
                {item.role === 'user' && (index % 2 !== 0) && (
                  <button className="play-button-speech" onClick={() => handlePlayClick(index)}>
                    &#9658;
                  </button>
                )}
                {item.content}
              </div>
            ))
        ) : (
          <div>No transcript available.</div>
        )}
      </div>
      {isVideoPopupVisible && (
        <div className="video-popup" onClick={closeVideoPopup}>
          <div className="video-popup-content" onClick={(e) => e.stopPropagation()}>
            <video controls autoPlay src={videoUrl} />
          </div>
        </div>
      )}
    </aside>
  );
};

export default CandidateInfo;