import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { doSignInWithEmailAndPassword, doPasswordReset } from './auth';
import { firestore } from './index';
import { useAuth } from './authContext';
import { useLocation } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './business-signin.css'; // Import the CSS file

const BusinessSignInPage = () => {
    const location = useLocation();
    const fromPractice = location.state?.from === 'practice';
    console.log("Received state in SignInPage:", fromPractice);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');

    const { userLoggedIn, setCurrentUser } = useAuth();

    const checkBusinessAccount = async (uid) => {
        try {
            const doc = await firestore.collection('businesses').doc(uid).get();
            console.log("checking business account:", doc.exists);
            return doc.exists;
        } catch (error) {
            console.error('Error checking business account:', error);
            return false;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                const userCredential = await doSignInWithEmailAndPassword(email, password);
                const uid = userCredential.user.uid;
                const isBusinessAccount = await checkBusinessAccount(uid);
                if (isBusinessAccount) {
                    setCurrentUser(userCredential.user);
                } else {
                    setErrorMessage('This account is not a business account.');
                    setIsSigningIn(false);
                }
            } catch (error) {
                setIsSigningIn(false);
                setErrorMessage('Incorrect email or password. Create an account below if this is your first time');
            }
        }
    };

    const handleForgotPassword = async () => {
        if (!email) {
            setMessage('Please enter your email address.');
            return;
        }
        try {
            console.log("Checking for user in Firestore:", email);
            const usersRef = collection(firestore, 'businesses');
            const q = query(usersRef, where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                console.log("User found in Firestore.");
                await doPasswordReset(email);
                setMessage('Password reset email sent. Please check your inbox.');
            } else {
                console.log("User not found in Firestore.");
                setMessage("No account found with this email address.");
            }
        } catch (error) {
            console.error('Error in handleForgotPassword:', error);
            setMessage('Error: ' + error.message);
        }
    };

    return (
        <div className="signin-page-container">
            {userLoggedIn && (<Redirect to={'/businesses'} replace={true} />)}
            {fromPractice && (
                <div className="practice-warning">
                    You must sign in to access the practice page.
                </div>
            )}
            <main className="signin-main-container">
                <div className="signin-form-container">
                    <h2>Let's get started.</h2>
                    <p>Take control of your recruitment process today.</p>
                    <form onSubmit={onSubmit} className="signin-form">
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                className="form-input"
                            />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <div className="password-container">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete='current-password'
                                    required
                                    value={password}
                                    onChange={(e) => { setPassword(e.target.value) }}
                                    className="form-input"
                                />
                                <button
                                    type="button"
                                    className="toggle-password"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? 'Hide' : 'Show'}
                                </button>
                            </div>
                            <button type="button" onClick={handleForgotPassword} className="forgot-password-link">Forgot Password?</button>
                        </div>

                        {errorMessage && (
                            <span className='error-message'>{errorMessage}</span>
                        )}
                        {message && (
                            <span className='message'>{message}</span>
                        )}

                        <button
                            type="submit"
                            disabled={isSigningIn}
                            className={`signin-button ${isSigningIn ? 'signing-in' : ''}`}
                        >
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>
                    </form>
                    <p className="signup-text">Don't have an account? <Link to={'/business-register'}>Sign up</Link></p>
                </div>
                {/* Image section */}
                <div className="signin-image-container">
                    <img src="/Login Art.png" alt="Sign in" className="signin-image" />
                </div>
            </main>
        </div>
    );
};

export default BusinessSignInPage;