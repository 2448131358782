import './welcomeBusinessInterview.css';
import './App.css';

const InterviewDone = () => {

    const navbar = document.querySelector('.menu-bar');
    if (navbar) {
      navbar.classList.add('hide');
    }

    return (
        <div>
          <div className="welcome-container">
            <h1>
                Thank you for interviewing! You can now close this tab.
            </h1>
          </div>
        </div>
      );
};

export default InterviewDone;