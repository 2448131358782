import React, { useState, useEffect, useRef } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from './authContext';
import './uploadresume.css';

const UploadResume = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const jobTitle = location.state ? location.state.jobTitle : ''; 
  const [selectedFile, setSelectedFile] = useState(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [usePreviousResume, setUsePreviousResume] = useState(false);
  const [hasResume, setHasResume] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const isBusinessRef = useRef(false);
  const [isValidInterview, setIsValidInterview] = useState(true); // Set to true for testing
  const [loading, setLoading] = useState(false); // Set to false for testing
  const [showInputs, setShowInputs] = useState(false); // State to control the visibility of the inputs
  const [fadeOut, setFadeOut] = useState(false); // State to control the fade-out animation
  const [stage, setStage] = useState(1); // setting state to 1 here to skip the welcome thingy
  const uidRef = useRef(currentUser ? currentUser.uid : null);
  const extractedUidRef = useRef(null);
  const jobPathRef = useRef(null);
  const uniqueIdRef = useRef(null);
  const beenPressed = useRef(false);

  console.log(`job title is: ${jobTitle}`)

  const checkInterviewValidity = async (uid, jobPath, uniqueId) => {
    const db = getFirestore();
    const jobPathWithSpaces = jobPath.replace(/-/g, ' ');

    const userRef = doc(db, 'businesses', uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const jobRef = doc(db, 'businesses', uid, jobPathWithSpaces, 'interviewees');
      const jobSnapshot = await getDoc(jobRef);

      if (jobSnapshot.exists() && jobSnapshot.data()[uniqueId]) {
        const intervieweeData = jobSnapshot.data()[uniqueId];
        if (intervieweeData.scores) {
          console.log("Interviewee exists but has scores, invalid interview.");
          return false;
        }
        if(jobSnapshot.exists() && intervieweeData.interviewStarted == true){
          console.log("Interviewee exists but has already started interview, invalid interview.");
          return false;
        }
        console.log("Interviewee exists");
        return true;
      }
    }
    console.log("Interviewee does not exist");
    return false;
  };

  useEffect(() => {
    const validateInterview = async () => {
      const pathParts = location.pathname.split('/');
      const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
  
      if (pathParts.length === 5 || (pathParts.length === 6 && whiteLabel)) {
        const extractedUid = pathParts[2];
        const jobPath = pathParts[3];
        const uniqueId = pathParts[4];
  
        extractedUidRef.current = extractedUid;
        jobPathRef.current = jobPath;
        uniqueIdRef.current = uniqueId;
        uidRef.current = uniqueId;
        setIsBusiness(true);
        isBusinessRef.current = true;
  
        const db = getFirestore();
        const jobPathWithSpaces = jobPath.replace(/-/g, ' ');
        const jobDesDocRef = doc(db, 'businesses', extractedUid, jobPathWithSpaces, 'jobDescription');
        const jobDesSnapshot = await getDoc(jobDesDocRef);
  
        if (jobDesSnapshot.exists()) {
          console.log("hello");
          const jobData = jobDesSnapshot.data();
        }
  
        if (extractedUid && jobPath && uniqueId) {
          const isValid = await checkInterviewValidity(extractedUid, jobPath, uniqueId);
          setIsValidInterview(isValid);
        } else {
          setIsValidInterview(false);
        }
      } else {
        setIsValidInterview(true);
      }
      setLoading(false);
    };
  
    validateInterview();
  }, [location.pathname]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setShowNextButton(true);
  };

  useEffect(() => {
    const startFlaskServer = async () => {
      try {
        const urlPath = isBusiness
          ? `/uploadResume/${extractedUidRef.current}/${jobPathRef.current}/${uidRef.current}`
          : `/uploadResume/${uidRef.current}/${jobTitle}`;

        //http://127.0.0.1:5000
        //https://fractalflaskapp-5c91849aadae.herokuapp.com
        const response = await fetch(`https://fractalflaskapp-5c91849aadae.herokuapp.com${urlPath}`, {
          method: 'GET',
        });
        console.log('Flask server started successfully');
      } catch (error) {
        console.error('Error starting Flask server:', error);
      }
    };

    if (uidRef.current) startFlaskServer();
  }, [uidRef.current, isBusiness, extractedUidRef.current, jobPathRef.current, uniqueIdRef.current]);

  const handleNext = async () => {
    if (!beenPressed.current) {
        console.log("Button has been pressed");
        beenPressed.current = true;
        setLoading(true);

        const formData = new FormData();

        if (selectedFile) {
            formData.append('resume', selectedFile);
        }

        try {
            const urlPath = isBusinessRef.current
                ? `/uploadResume/${extractedUidRef.current}/${jobPathRef.current}/${uidRef.current}`
                : `/uploadResume/${uidRef.current}/${jobTitle}`;

            const response = await fetch(`https://fractalflaskapp-5c91849aadae.herokuapp.com${urlPath}`, {
                method: 'POST',
                body: formData
            });
            console.log('Status code:', response.status);

            const responseData = await response.json();
            console.log('Response:', responseData);

            if (response.ok) {
                // Extract topics_list from the response
                const topicsList = responseData.topics_list;
                console.log('Generated Topics List:', topicsList);

                const pathParts = location.pathname.split('/');
                const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
                console.log("whiteLabel: ", whiteLabel);
                const redirectPath = isBusinessRef.current 
                    ? `/getready/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}${whiteLabel ? '/ai-interview' : ''}`
                    : '/getready';

                await storeUserEmail(); // Store the user's email before redirecting
                setFadeOut(true); // Trigger fade-out animation

                setTimeout(() => {
                    history.push({
                        pathname: redirectPath,
                        state: { 
                            jobTitle: jobTitle, 
                            isBusiness: isBusiness,
                            topicsList: topicsList // Pass topicsList as a prop to the next page
                        }
                    });
                }, 1000); // Delay to allow fade-out animation to complete
            } else {
                console.error('Error uploading resume');
                beenPressed.current = false; // Reset beenPressed
            }
        } catch (error) {
            console.error('Error uploading resume:', error);
            beenPressed.current = false; // Reset beenPressed
        }
    }
};



  const storeUserEmail = async () => {
    if (!currentUser) return;
    const db = getFirestore();
    const userRef = doc(db, 'users', currentUser.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      await setDoc(userRef, { email: currentUser.email });
      console.log('Email stored successfully');
    } else {
      const userData = userSnapshot.data();
      if (!userData.email) {
        await setDoc(userRef, { email: currentUser.email }, { merge: true });
        console.log('Email added successfully');
      } else {
        console.log('Email already exists');
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInputs(true);
    }, "To ask the most detailed questions, I'll need to look at your resume.".split(" ").length * 0.2); // Adjust the delay to match the animation delay
  
    return () => clearTimeout(timer);
  }, []);  

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === 'Enter') {
      //   setFadeOut(true); 
      //   setTimeout(() => {
      //     handleNext();
      //   }, 1000); 
      // } else
      // if (event.key === ' ') {
      //   setStage(stage + 1); // Move to the next stage when spacebar is pressed
      // }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [stage]);

  if (loading && beenPressed.current) {
    return <LoadingPopup />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="upload-resume-container">
      {isValidInterview ? (
        stage === 0 ? (
          <>
            <h1 className={`upload-resume-title ${fadeOut ? 'fade-out' : ''}`}>
              {"Welcome to your interview. I am your AI Interviewer.".split(" ").map((word, index) => (
                <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3}s` }}>
                  {word}&nbsp;
                </span>
              ))}
            </h1>
            <h2 className={`upload-resume-subtitle ${fadeOut ? 'fade-out' : ''} white-text`}>
              {"Press spacebar to continue".split(" ").map((word, index) => (
                <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3 + 3}s` }}>
                  {word}&nbsp;
                </span>
              ))}
            </h2>
          </>
        ) : stage === 1 ? (
          <>
            <h1 className={`upload-resume-title`}>
              {"To ask the most detailed questions, I'll need to look at your resume.".split(" ").map((word, wordIndex) => (
                <span key={wordIndex} className="fade-in-char" style={{ animationDelay: `${wordIndex * 0.1}s` }}>
                    {word}&nbsp;
                </span>
                ))}
            </h1>
            {showInputs && (
              <div className={`inputs-container ${fadeOut ? 'fade-out' : ''}`}>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="resume-upload"
                />
                <label htmlFor="resume-upload" className="upload-resume-button">Upload Here</label>
                {selectedFile && (
                  <p style={{ marginTop: '20px' }}>{selectedFile.name}</p>
                )}
                {showNextButton && (
                  <button
                    type="button"
                    className="uploadresume-next-button"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                )}
              </div>
            )}
          </>
        ) : null
      ) : (
        <h1 className={`upload-resume-title ${fadeOut ? 'fade-out' : ''}`}>
          {"Interview has expired".split(" ").map((word, index) => (
            <span key={index} className="fade-in-char" style={{ animationDelay: `${index * 0.3}s` }}>
              {word}&nbsp;
            </span>
          ))}
        </h1>
      )}
    </div>
  );
};

const LoadingPopup = () => (
  <div className="loading-popup">
    <div className="loading-icon"></div>
    <p>Loading...</p>
  </div>
);

export default UploadResume;