import React, { useState, useEffect, useCallback } from 'react';
import { FaPlus, FaEnvelope, FaArrowRight, FaStar, FaSearch, FaRegStar, FaUserFriends, FaUserCheck, FaDesktop, FaLink, FaCopy, FaDownload, FaTrash } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, deleteDoc, arrayRemove } from 'firebase/firestore/lite';
import { useAuth } from './authContext';
import { app } from './index';
import './BusinessesPage.css';
import BusinessSidebar from './businessSidebar';
import AddJobPopup from './AddJobPopup'; // Import for reference, not used in Positions
import UploadEmailsPopup from './UploadEmailsPopup';
import ConfirmDeletePopup from './ConfirmDeletePopup';
import CandidateInfo from './candidateInfo';
import { collection, getDocs } from 'firebase/firestore/lite';
import OutreachSection from './OutreachSection';
import SettingsPopup from './SettingsPopup';
import HelpPopup from './HelpPopup';
import PositionsSection from './PositionsSection'; // Import the PositionsSection component

function BusinessesPage() {
  const location = useLocation();
  const { selectedJob } = location.state || {}; // Get the selected job from the state

  const [selectedJobRow, setSelectedJobRow] = useState(null);
  const [selectedApplicantRows, setSelectedApplicantRows] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [filter, setFilter] = useState('overall');
  const [jobTitles, setJobTitles] = useState([]);
  const [interviewees, setInterviewees] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [emailSentMessage, setEmailSentMessage] = useState('');
  const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { currentUser } = useAuth();
  const [company, setCompany] = useState('');
  const history = useHistory();
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [starredCandidates, setStarredCandidates] = useState({});
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [completedInterviews, setCompletedInterviews] = useState(0);
  const [pendingInterviews, setPendingInterviews] = useState(0);
  let [personalityTraits, setPersonalityTraits] = useState([]);
  const [activeSection, setActiveSection] = useState('positions');
  const [generatedLink, setGeneratedLink] = useState('');
  
  useEffect(() => {
    if (selectedJob && jobTitles.length > 0) {
      const index = jobTitles.indexOf(selectedJob);
      if (index !== -1) {
        setSelectedJobRow(index); // Set the selected job row based on the title
      }
    }
  }, [selectedJob, jobTitles]);

  const handleButtonClick = (buttonName, selectedJobTitle = null) => {
    console.log(`${buttonName} button clicked`);
    if (buttonName === 'Outreach') {
      setIsUploadPopupOpen(true);
    } else {
      setActiveSection(buttonName.toLowerCase()); // Set the active section
      if (buttonName === 'Candidates' && selectedJobTitle) {
        const index = jobTitles.indexOf(selectedJobTitle);
        if (index !== -1) {
          setSelectedJobRow(index); // Set the selected job row
        }
      }
      setIsUploadPopupOpen(false); // Ensure the upload popup is closed if not Outreach
    }
  };
  

  const calculateAverageScore = (scores) => {
    if (!scores) return 0;
  
    let weightedScores = Object.keys(scores).map((trait) => {
      let weight = personalityTraits[trait] / 100;
      if (isNaN(weight)) {
        weight = 0;
      }
      return scores[trait] * weight;
    });
    console.log("got here")
    let totalWeight = Object.keys(personalityTraits).reduce((sum, trait) => {
      if (personalityTraits[trait]) {
        return sum + personalityTraits[trait];
      }
      return sum;
    }, 0);
    console.log("yeet")
    let totalScore = weightedScores.reduce((sum, score) => sum + score, 0);
    console.log("totalWeight: ", totalWeight);
    return totalWeight ? Math.round(totalScore / (totalWeight / 100)) : 0;
  };
  
  

  const sortedInterviewees = Object.keys(interviewees).map((key) => {
    const interviewee = interviewees[key];
    const averageScore = calculateAverageScore(interviewee.scores);
    console.log("averagescore: ", averageScore);
    return { ...interviewee, averageScore };
  }).sort((a, b) => {
    if (filter !== 'overall') {
      return (b.scores?.[filter] || 0) - (a.scores?.[filter] || 0);
    }
    return b.averageScore - a.averageScore;
  });

  const filteredInterviewees = sortedInterviewees.filter((interviewee) => {
    const matchesSearch = interviewee.name ? interviewee.name.toLowerCase().includes(searchQuery.toLowerCase()) : false;
    const matchesStarred = activeSection === 'starred' ? interviewee.starred : true; // Only show starred if in starred section
    return matchesSearch && matchesStarred;
  });  

  const handleRowClick = (index) => {
    console.log("row clicked", index);
    console.log("interviewees object:", interviewees);
  
    setSelectedRow((prevSelectedRow) => {
      const newSelectedRow = prevSelectedRow === index ? null : index;
      const newSelectedCandidate = newSelectedRow === null ? null : filteredInterviewees[index];
      const overallScore = newSelectedCandidate ? newSelectedCandidate.averageScore : null; // Calculate overallScore
  
      if (newSelectedCandidate) {
        setSelectedCandidate({
          ...newSelectedCandidate,
          overallScore,
          uid: newSelectedCandidate.uid // Ensure UID is passed
        });
      } else {
        setSelectedCandidate(null);
      }
  
      console.log("selectedCandidate set to:", newSelectedCandidate);
      return newSelectedRow;
    });
  
    handleApplicantRowClick(index);
  };  

  

  const handleStarClick = async (event, index) => {
    event.stopPropagation(); // Prevent the row click event from firing
  
    // Find the interviewee key based on the filtered interviewees
    const filteredIntervieweeKey = Object.keys(interviewees).find(key => interviewees[key].submission_time === filteredInterviewees[index].submission_time);
    const interviewee = interviewees[filteredIntervieweeKey];

    if (!interviewee || !interviewee.submission_time) {
      return;
    }
  
    if (interviewee) {
      const newStarredValue = !interviewee.starred;
      try {
        const db = getFirestore(app);
        const jobTitle = jobTitles[selectedJobRow];
        const intervieweeDocRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'interviewees');
  
        // Update the starred field in Firestore
        await updateDoc(intervieweeDocRef, {
          [`${filteredIntervieweeKey}.starred`]: newStarredValue
        });
  
        // Update the local state to reflect the change
        setInterviewees((prevInterviewees) => ({
          ...prevInterviewees,
          [filteredIntervieweeKey]: { ...interviewee, starred: newStarredValue },
        }));
      } catch (error) {
        console.error("Error updating starred field: ", error);
      }
    }
  };  

  const handleJobRowClick = (index) => {
    setSelectedJobRow(index);
    adjustDropdownWidth();
  };

  const adjustDropdownWidth = () => {
    const selectElement = document.getElementById('position');
    if (selectElement) {
      selectElement.style.width = 'auto';
      const width = selectElement.scrollWidth;
      selectElement.style.width = `${width}px`;
    }
  };

  useEffect(() => {
    adjustDropdownWidth();
  }, [jobTitles]);

  useEffect(() => {
    const checkUserDocument = async () => {
      if (!currentUser) {
        console.log('No current user, redirecting to sign-in.');
        history.push('/sign-in');
        return;
      }

      const db = getFirestore(app);
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        console.log('User document does not exist, redirecting to sign-in.');
        history.push('/sign-in');
      } else {
        const userData = userDoc.data();
        const jobs = userData.currentJobs || [];
        setJobTitles(jobs);

        const companyName = userData.company;
        if (companyName) {
          setCompany(companyName);
        }

        // Set initial selected job row and fetch interviewees
        if (jobs.length > 0) {
          setSelectedJobRow(0);
        }
      }
    };

    checkUserDocument();
  }, [currentUser, history]);

  useEffect(() => {
    const fetchJobDescription = async () => {
      if (selectedJobRow !== null) {
        const db = getFirestore(app);
        const jobTitle = jobTitles[selectedJobRow];
        const jobDescriptionRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'jobDescription');
        const jobDescriptionDoc = await getDoc(jobDescriptionRef);

        if (jobDescriptionDoc.exists()) {
          const jobDescriptionData = jobDescriptionDoc.data();

          const traits = jobDescriptionData.personalityTraits || {};
          if (typeof traits === 'object' && !Array.isArray(traits)) {
            try{
              console.log("setting personality triats")
              setPersonalityTraits(traits);
            }
            catch {
              console.log("couldn't set those traits")
            }
          } else {
            console.error('personalityTraits is not a dictionary:', traits);
          }

          if (jobDescriptionData.generatedLink) {
            setGeneratedLink(jobDescriptionData.generatedLink);
          } else {
            setGeneratedLink('');
          }
        } else {
          setGeneratedLink('');
        }
      }
    };
    fetchJobDescription();
  }, [selectedJobRow, jobTitles, currentUser]);

  useEffect(() => {
    const fetchInterviewees = async () => {
      if (selectedJobRow !== null) {
        const db = getFirestore(app);
        const jobTitle = jobTitles[selectedJobRow];
        const jobDocRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'interviewees');
        const jobDoc = await getDoc(jobDocRef);
  
        if (jobDoc.exists()) {
          const intervieweesData = jobDoc.data();
  
          // Include UID in the interviewee data
          const intervieweesWithUid = Object.keys(intervieweesData).reduce((acc, key) => {
            acc[key] = { ...intervieweesData[key], uid: key }; // Assuming the Firestore document key is the uid
            return acc;
          }, {});
  
          setInterviewees(intervieweesWithUid);
          setTotalCandidates(Object.keys(intervieweesWithUid).length);
  
          // Calculate completed and pending interviews
          let completedCount = 0;
          let pendingCount = 0;
          Object.values(intervieweesWithUid).forEach(interviewee => {
            if (interviewee.scores) {
              completedCount++;
            } else {
              pendingCount++;
            }
          });
          setCompletedInterviews(completedCount);
          setPendingInterviews(pendingCount);
        }
      }
    };
  
    fetchInterviewees();
  }, [selectedJobRow, jobTitles, currentUser]);  

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  };

  const handleApplicantRowClick = (index) => {
    setSelectedApplicantRows((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleAddJob = async (jobTitle, jobDescription, customQuestions, personalityTraits) => {
    console.log("creating job")
    if (!currentUser) return;
  
    const db = getFirestore(app);
    const generatedLink = `https://safirahiring.com/welcomeinterview/${jobTitle}/${currentUser.uid}`;
  
    // Convert personalityTraits to lowercase and create a map
    const personalityTraitsMap = {};
    for (const [trait, weighting] of Object.entries(personalityTraits)) {
      personalityTraitsMap[trait.toLowerCase()] = weighting;
    }
  
    const createdAt = new Date().toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
  
    try {
      // Create a new document for the job with an empty 'interviewees' field
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'interviewees');
      await setDoc(jobDocRef, {});
      console.log("done");
  
      const jobDesDocRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'jobDescription');
      await setDoc(jobDesDocRef, {
        generatedLink,
        jobDescription,
        customQuestions,
        personalityTraits: personalityTraitsMap,
        createdAt  // Added createdAt field
      });
      console.log("done2");
  
      // Update the currentJobs field to include the new job title
      const userDocRef = doc(db, 'businesses', currentUser.uid);
      await updateDoc(userDocRef, {
        currentJobs: arrayUnion(jobTitle)
      });
  
      // Update the state with the new job title
      setJobTitles((prevJobTitles) => [...prevJobTitles, jobTitle]);
      setIsPopupOpen(false);
      window.location.reload(); // Reload the whole page after adding the job
    } catch (error) {
      console.error("Error adding job: ", error);
    }
  };
  
  

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  const handleAddJobClick = () => {
    setIsPopupOpen(true);
  };

  const handleUploadEmailsClick = () => {
    setIsUploadPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setActiveSection('candidates');
  };



  const handleUploadPopupClose = () => {
    setIsUploadPopupOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (jobToDelete !== null) {
      const db = getFirestore(app);
      const jobTitle = jobTitles[jobToDelete];
      const jobDocRef = doc(db, 'businesses', currentUser.uid, jobTitle, 'interviewees');
      await deleteDoc(jobDocRef);

      setJobTitles((prevJobTitles) => prevJobTitles.filter((_, index) => index !== jobToDelete));
      setJobToDelete(null);
      setIsConfirmPopupOpen(false);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmPopupOpen(false);
    setJobToDelete(null);
  };

  const handleGenerateLinkClick = () => {
    window.location.reload();
  };

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(generatedLink).then(() => {
      console.log('Link copied to clipboard');
    }).catch((error) => {
      console.error('Error copying link: ', error);
    });
  };
  const handleDownloadCSV = () => {
    const headers = "Name,Email\n";
    const csvContent = headers + filteredInterviewees.map(e => `${e.name},${e.email}`).join("\n");
    const jobTitle = jobTitles[selectedJobRow].replace(/\s+/g, '-');
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `safira-${jobTitle}-candidates.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteJobClick = () => {
    setIsConfirmPopupOpen(true);
  };

  return (
    <div className={`businesses-page ${activeSection === 'starred' ? 'starred-candidates-page' : ''}`}>
      <BusinessSidebar handleButtonClick={handleButtonClick} currentUser={currentUser} company={company} activeSection={activeSection} />
  
      <main className="main-content">
        {activeSection === 'positions' || activeSection === 'help' ? (
          <>
          {activeSection === 'help' && <HelpPopup />}
          {activeSection === 'positions' && <PositionsSection jobTitles={jobTitles} handleButtonClick={handleButtonClick} />}
          </>
        ) : (
          <>
            {/* The rest of your main content goes here */}
            {jobTitles.length === 0 ? (
              <div className="empty-job-titles">
                <p>No Active Positions Currently</p>
              </div>
            ) : activeSection === 'outreach' ? (
              <OutreachSection
                jobTitles={jobTitles}
                emailSentMessage=""
                onClose={handleUploadPopupClose}
                onSubmit={() => {}}
              />
            ) : (
              <>
                <header className="main-header">
                  <div className="position-dropdown-container">
                    <label htmlFor="position">Position:</label>
                    <select id="position" value={selectedJobRow} onChange={(e) => handleJobRowClick(e.target.value)}>
                      {jobTitles.map((title, index) => (
                        <option key={index} value={index}>{title}</option>
                      ))}
                    </select>
                  </div>
                  {generatedLink ? (
                    <div className="copy-link-button" onClick={handleCopyLinkClick}>
                      {`Copy Interview Link:`}&nbsp;&nbsp;
                      <div className='link-in-button'>
                        <p>{`${generatedLink.substring(0, 20)}...`}</p>
                      </div>
                      <FaCopy className="copy-icon" />
                    </div>
                  ) : (
                    <div className="generate-link-button" onClick={handleGenerateLinkClick}>
                      <FaLink className="link-icon" />
                      Reload for link
                    </div>
                  )}
                </header>
                {activeSection !== 'starred' && (
                  <section className="stats-section">
                    <div className="stat-card">
                      <div className="stat-icon circle-icon"><FaUserFriends /></div>
                      <div className="stat-info">
                        <p>Total Candidates</p>
                        <h3>{totalCandidates}</h3>
                      </div>
                    </div>
                    <div className="stat-card">
                      <div className="stat-icon green-circle-icon"><FaUserCheck /></div>
                      <div className="stat-info">
                        <p>Completed Interviews</p>
                        <h3>{completedInterviews}</h3>
                      </div>
                    </div>
                    <div className="stat-card">
                      <div className="stat-icon yellow-circle-icon"><FaDesktop /></div>
                      <div className="stat-info">
                        <p>Pending Interviews</p>
                        <h3>{pendingInterviews}</h3>
                      </div>
                    </div>
                  </section>
                )}
                <section className="candidates-section">
                  <header className="candidates-header">
                    <h1>{activeSection === 'starred' ? 'Starred Candidates' : 'All Candidates'}</h1>
                    <div className="header-controls">
                      <div className="search-bar">
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
                        <i className="search-icon fas fa-search"></i>
                      </div>
                      <div className="sort-dropdown-container">
                        <span>Sort by</span>
                        <select id="sort" value={filter} onChange={handleFilterChange}>
                          <option value="overall">Overall</option>
                          {Object.keys(personalityTraits).map((trait, index) => (
                            <option key={index} value={trait}>{trait}</option>
                          ))}
                        </select>
                      </div>

                      {activeSection === 'starred' && (
                        <button className="download-button" onClick={handleDownloadCSV}>
                          <FaDownload />
                        </button>
                      )}
                    </div>
                  </header>
                  <table className="candidates-table">
                    <thead>
                      <tr>
                        <th>Candidate Name</th>
                        <th>Starred</th>
                        <th>Score</th>
                        <th>Email</th>
                        <th>Completion Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInterviewees.map((interviewee, index) => (
                        <tr
                          key={index}
                          onClick={() => handleRowClick(index)}
                          className={selectedRow === index ? 'selected-row' : ''}
                        >
                          <td>{interviewee.name}</td>
                          <td>
                            <span onClick={(event) => handleStarClick(event, index)}>
                              {interviewee.starred ? <FaStar style={{ color: 'orange' }} /> : <FaRegStar />}
                            </span>
                          </td>
                          <td>{filter === 'overall' ? interviewee.averageScore : (interviewee.scores?.[filter] || '-')}</td>
                          <td>{interviewee.email}</td>
                          <td>{interviewee.submission_time ? formatTimestamp(interviewee.submission_time) : '-'}</td>
                          <td>
                            <span className={`status-label ${
                              interviewee.scores ? 'completed' :
                              interviewee.interviewStarted ? 'in-progress' : 'not-started'
                            }`}>
                              {interviewee.scores ? 'Completed' :
                              interviewee.interviewStarted ? 'In Progress' : 'Not Started'}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </>
            )}
          </>
        )}
      </main>
      <AddJobPopup isOpen={isPopupOpen} onClose={handlePopupClose} onSubmit={handleAddJob} />
      <ConfirmDeletePopup
        isOpen={isConfirmPopupOpen}
        jobTitle={jobTitles[selectedJobRow]}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      {selectedCandidate && <CandidateInfo selectedCandidate={selectedCandidate} videos={selectedCandidate.videos} overallScore={selectedCandidate.overallScore} jobTitle={jobTitles[selectedJobRow]}/>}
    </div>
  );
}

export default BusinessesPage;
