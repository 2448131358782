import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import videoFile from './mandelbrotvid.mp4';
import { Superpowers } from './components/superpowers';
import './App.css';
import manInterviewing from './maninterviewing.png';
import statsPic from './statspic.png';
import scrollDownArrow from './scrollIcon.png';
import TextScramble from './textscramble.js';
import myImage from './man 1.png';
import myImage2 from './dragon 1.png';
import myImage3 from './manreview 1.png';
import myImage4 from './manriding 2.png';
import myImage5 from './Union.png';
import myImage6 from './Union-2.png';
import myImage7 from './Union-3.png';
import myImage8 from './Union-4.png';
import myImage9 from './Union-5.png'; // Import your new image
import myImage10 from './demo.png';
import myImage11 from './watchDEMO.png';

function HomePage() {
  const headerRef = useRef(null);
  const observerRef = useRef(null);
  const playerRef = useRef(null);
  const finalGridRef = useRef(null); // Add a ref for the final grid element

  useEffect(() => {
    const targets = document.querySelectorAll('.contentBody');
    const checkVisibility = () => {
      targets.forEach(target => {
        const rect = target.getBoundingClientRect();
        const fifthHeight = rect.height / 5;
        const elementMiddle = rect.top - fifthHeight;
        const bottomOfViewport = window.innerHeight;
        if (elementMiddle <= bottomOfViewport && !target.classList.contains('scrambled')) {
          target.classList.add('visible', 'scrambled');
          const fx = new TextScramble(target);
          const text = target.getAttribute('data-text');
          fx.setText(text).then(() => {
            console.log('Scramble complete!');
          });
        }
      });
    };
    window.addEventListener('scroll', checkVisibility);
    checkVisibility();
    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  useEffect(() => {
    const navbar = document.querySelector('.menu-bar');
    if (navbar) {
      navbar.classList.remove('hide');
    }
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: [0.25]
    });

    const targets = document.querySelectorAll('.info-text, .infopic, .contentHeader, .contentBody');
    targets.forEach(target => {
      observerRef.current.observe(target);
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('youtube-video', {
        events: {
          'onReady': onPlayerReady
        }
      });
    };

    const onPlayerReady = (event) => {
      document.querySelector('.play-button').addEventListener('click', () => {
        event.target.playVideo();
      });
    };
  }, []);

  const scrollToBottom = () => {
    finalGridRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const superpowers = ['Confidence.', 'Skills.', 'Future.'];

  return (
    <Router>
      <div>
        <Superpowers superpowers={superpowers} className="superpowers-text superpowerpositioning" />
        <button onClick={scrollToBottom} className='watch-demo-button'>
            Watch Demo
        </button>
        <div className="video-container custom-video-position">
          <video className="videoTag" autoPlay loop muted playsInline>
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <img src={scrollDownArrow} alt="Scroll down" className="scroll-arrow" />
        <div className="content grid-container--fill Animated--in">
          <div className="top-grid-element">
            <div className="contentHeader">Interview.</div>
            <div className="contentBody" data-text="Safira is a tough opponent. Applicants are asked questions about their resume, the job description, and any custom questions the company might have.">
              Safira is a tough opponent. Applicants are asked questions about their resume, the job description, and any custom questions the company might have.
            </div>
            <img src={myImage} alt="Man 1" className="top-grid-image" style={{ marginLeft: '120px', marginTop: '50px', transform: 'scale(0.782)' }} />
            <img src={myImage2} alt="Dragon 1" className="top-grid-image" style={{ marginLeft: '270px', marginTop: '150px', transform: 'scale(0.7344)', position: 'absolute', top: '150px' }} />
            <img src={myImage8} alt="My New Image" className="top-grid-image" style={{ marginLeft: 'calc(50% + 620px)', marginTop: '200px', transform: 'scale(0.7344)', position: 'absolute', top: '200px' }} />
            <img src={myImage9} alt="My Custom Image" className="top-grid-image" style={{ marginLeft: 'calc(50% + 620px)', marginTop: '160px', transform: 'scale(0.7344)', position: 'absolute', top: '160px' }} />
          </div>
          <div className="middle-grid-element">
            <div className="contentHeader">Review and Repeat.</div>
            <div className="contentBody" data-text="Safira takes notes. See strengths and weaknesses of every candidate before interviewing them.">
              Safira takes notes. See strengths and weaknesses of every candidate before interviewing them.
            </div>
            <img src={myImage3} alt="manreview 1" className="middle-grid-image" style={{ left: '50%', top: 'calc(50% - 375px)', transform: 'translate(-50%, -50%) scale(0.68)', position: 'absolute' }} />
            <img src={myImage6} alt="my new image" className="middle-grid-image" style={{ left: 'calc(50% + 620px)', top: 'calc(50% - 474px)', transform: 'translate(-50%, -50%) scale(0.68)', position: 'absolute' }} />
            <img src={myImage7} alt="my new image" className="middle-grid-image" style={{ left: 'calc(50% + 620px)', top: 'calc(50% - 258px)', transform: 'translate(-50%, -50%) scale(0.68)', position: 'absolute' }} />
          </div>
          <div className="bottom-grid-element">
            <div className="contentHeader">Win.</div>
            <div className="contentBody" data-text="Move on to the next challenge. Now that your skills are sharpened against Saphira, an interview is the least of your worries.">
              Move on to the next challenge. Now that your skills are sharpened against Saphira, an interview is the least of your worries.
            </div>
            <img src={myImage4} alt="manriding 2" className="bottom-grid-image" style={{ left: '50%', top: 'calc(50% + 460px)', transform: 'translate(-50%, -50%) scale(0.7742)', position: 'absolute' }} />
            <img src={myImage5} alt="my new image" className="bottom-grid-image" style={{ left: 'calc(50% + 620px)', top: 'calc(50% + 460px)', transform: 'translate(-50%, -50%) scale(0.7742)', position: 'absolute' }} />
          </div>
          <div className="final-grid-element background-image" ref={finalGridRef} style={{ marginTop: '0px', paddingBottom: '330px' }}>
            <div className="video-wrapper" style={{ backgroundColor: 'transparent', marginTop: '95px', marginLeft: '6px', transform: 'scale(0.8)', zIndex: 1 }}>
              <iframe
                id="youtube-video"
                className="youtube-iframe"
                src="https://www.youtube.com/embed/paS9gurVMrw"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ backgroundColor: 'transparent', width: '100%', height: '500px' }} // Adjusted height
              ></iframe>
              <div className="play-button" onClick={() => playerRef.current.playVideo()}>
                ▶
              </div>
            </div>
            <div className="video-wrapper" style={{ backgroundColor: 'transparent', marginTop: '95px', marginLeft: '6px', transform: 'scale(0.8)', zIndex: 1 }}>
              <iframe
                id="youtube-video-2"
                className="youtube-iframe"
                src="https://www.youtube.com/embed/EIT7RZqaDLg?enablejsapi=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ backgroundColor: 'transparent', width: '100%', height: '500px' }} // Adjusted height
              ></iframe>
              <div className="play-button" onClick={() => playerRef.current.playVideo()}>
                ▶
              </div>
            </div>
            <a href="https://calendly.com/aman04shah/15-min-meeting?month=2024-07" className="schedule-demo-button" style={{ position: 'absolute', top: 'calc(50% + 180px)', left: 'calc(50% + 5px)', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                Schedule a Demo
            </a>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default HomePage;