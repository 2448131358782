import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from './authContext';
import { app } from './index';
import PracticePage from './practice_page';
import { getFirestore, doc, updateDoc, getDoc, setDoc} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './interview.css';
import { getBottomNavigationUtilityClass, useForkRef } from '@mui/material';

const Interview = () => {
    const notSoSecret = '74d08e7969cd26555ad59972d0aaba7329560163';
    const { currentUser } = useAuth();
    const [showAlert, setShowAlert] = useState(true);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const location = useLocation();
    const videoRef = useRef(null);
    const jobTitle = location.state ? location.state.jobTitle : '';
    const aiAudioRef = useRef(null);
    const db = getFirestore(app);
    const [aiSpeaking, setAiSpeaking] = useState(false);
    const interviewCompleted = useRef(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const recognitionRef = useRef(null);
    const history = useHistory();
    const questionsColumnRef = useRef(null);
    const [isRecognizing, setIsRecognizing] = useState(false);
    const isBusiness = location.state ? location.state.isBusiness : '';
    const [isValidInterview, setIsValidInterview] = useState(false);
    const extractedUidRef = useRef(null);
    const jobPathRef = useRef(null);
    const jobPathTitleRef = useRef(null);
    const uniqueIdRef = useRef(null);
    const [voiceSpeed, setVoiceSpeed] = useState(1.15);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);
    const thistimeLimit = useRef(90);
    const [timeLeft, setTimeLeft] = useState(90);
    const timerRef = useRef(null);
    const [serverCallCount, setServerCallCount] = useState(0);
    
    let failedServerCalls = 0;
    let chat_log = [];
    let { topics_list } = location.state || {};
    let queue = [];
    let current_topic_log = [];
    let follow_up_count = 0;
    let outBrowserCount = 0;
    let prev_newChat = "";
    let prematureSpace = "";

    const deepgramSocketRef = useRef(null);
    const userSpeakingRef = useRef(false);  //one
    const aiSpeakingRef = useRef(false); // two
    const transcriptRef = useRef('');
    const serverPendingRef = useRef(false);

    useEffect(() => {
        
        const validateInterview = async () => {
          const pathParts = location.pathname.split('/');
          const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
      
          if (pathParts.length === 5 || (pathParts.length === 6 && whiteLabel)) {
            const extractedUid = pathParts[2];
            const jobPath = pathParts[3];
            const uniqueId = pathParts[4];
            extractedUidRef.current = extractedUid;
            jobPathRef.current = jobPath;
            jobPathTitleRef.current = jobPathRef.current.replace(/-/g, ' ');
            uniqueIdRef.current = uniqueId;

            await fetchTimeLimitFromFirestore(extractedUid, jobPath, uniqueId);
      
            if (extractedUid && jobPath && uniqueId) {
              const jobPathWithSpaces = jobPath.replace(/-/g, ' ');
              const jobDesDocRef = doc(db, `businesses/${extractedUid}/${jobPathWithSpaces}/interviewees`);
              const jobDesSnapshot = await getDoc(jobDesDocRef);
      
              if (jobDesSnapshot.exists()) {
                const jobData = jobDesSnapshot.data();
                if (jobData[uniqueId] && jobData[uniqueId].interviewStarted) {
                  handleEndInterview();
                } else {
                  await updateDoc(jobDesDocRef, { [`${uniqueId}.interviewStarted`]: true });
                  console.log(jobData[uniqueId].interviewStarted);
                  const isValid = await checkInterviewValidity(extractedUid, jobPath, uniqueId);
                  if (!isValid) {
                    handleEndInterview();
                  }
                  setIsValidInterview(isValid);
                }
              } else {
                handleEndInterview();
              }
            } else {
              setIsValidInterview(false);
              handleEndInterview();
            }
          } else {
            setIsValidInterview(true);
          }
        };
      
        validateInterview();
      }, [location.pathname]); 

    // Helper function to convert M:SS to seconds
    const convertTimeLimitToSeconds = (timeLimit) => {
        console.log("timeLimit:", timeLimit);
        const [minutes, seconds] = timeLimit.split(':').map(Number);
        console.log("minsec:", minutes, seconds);
        return minutes * 60 + seconds;
    };

    // Fetch timeLimit from Firestore
    const fetchTimeLimitFromFirestore = async (uid, jobPath, uniqueId) => {
        const jobPathWithSpaces = jobPath.replace(/-/g, ' ');
        const jobDesDocRef = doc(db, `businesses/${uid}/${jobPathWithSpaces}/jobDescription`);
        const jobDesSnapshot = await getDoc(jobDesDocRef);

        if (jobDesSnapshot.exists()) {
            const jobData = jobDesSnapshot.data();
            const timeLimit = jobData.timeLimit; // Assuming timeLimit is a field in the document
            if (timeLimit) {
                const timeInSeconds = convertTimeLimitToSeconds(timeLimit);
                setTimeLeft(timeInSeconds);
                thistimeLimit.current = timeInSeconds;
                console.log("thistimelimit:", thistimeLimit.current);
                console.log("timeInSeconds:", timeInSeconds);
            } else {
                console.log("no timeLimit found");
                setTimeLeft(90); // Default to 90 seconds if timeLimit not found
            }
        }
    };

    useEffect(() => {
        console.log("thistimeLimit updated:", thistimeLimit.current);
    }, [thistimeLimit.current]);

    useEffect(() => {
        let streamRef = null;
    
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                streamRef = stream;
    
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunksRef.current.push(event.data);
                    }
                };
                console.log(`video ref current = ${videoRef.current}`);
            })
            .catch((error) => {
                console.error('Error accessing camera and microphone:', error);
            });
    
        return () => {
            if (streamRef) {
                streamRef.getTracks().forEach(track => track.stop());
                console.log("Camera and microphone are now turned off.");
            }
        };
    }, []); 

    const initializeVideo = async () => {
        console.log("initializeVideo was called");
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const initializeInterview = async () => {
        console.log("initializeInterview was called");
        setInterviewStarted(true);
        setShowAlert(false);
        await communicateWithServer();
        await initializeDeepgram();

    };

    const initializeDeepgram = async () => {
        console.log("Initializing Deepgram");
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            mediaRecorderRef.current = mediaRecorder;

            const socket = new WebSocket('wss://api.deepgram.com/v1/listen?interim_results=true', [
                'token',
                notSoSecret,
            ]);
            deepgramSocketRef.current = socket;

            socket.onopen = () => {
                console.log('Connected to Deepgram WebSocket');
                mediaRecorder.addEventListener('dataavailable', (event) => {
                    if (event.data.size > 0 && socket.readyState === 1) {
                        socket.send(event.data);
                    }
                });
                mediaRecorder.start(250);
            };

            let silenceTimer = null;

            socket.onmessage = async (message) => {
                const received = JSON.parse(message.data);

                if (received.channel && received.channel.alternatives[0]) {
                    const transcriptPiece = received.channel.alternatives[0].transcript;

                    if (transcriptPiece.trim() !== '') {
                        if (aiSpeakingRef.current) {
                            return;
                        }
                        if (received.is_final) {
                            transcriptRef.current += ' ' + transcriptPiece;
                            console.log('User finished speaking:', transcriptPiece);
                            if (userSpeakingRef.current) { 
                                // Clear any existing timer
                                if (silenceTimer) clearTimeout(silenceTimer);
                                
                                // Set a new timer
                                silenceTimer = setTimeout(() => {
                                    if (!userSpeakingRef.current) {
                                        clearInterval(timerRef.current);
                                        communicateWithServer(transcriptRef.current);
                                        console.log('User speaking changed to false');
                                        userSpeakingRef.current = false;
                                    }
                                }, 2000);
                            }
                            else{
                                console.log("user not speaking")
                            }
                        } else {
                            if (!userSpeakingRef.current) {
                                console.log('User speaking changed to true');
                                userSpeakingRef.current = true;
                                if (silenceTimer) clearTimeout(silenceTimer);
                            }
                            console.log('User is speaking:', transcriptPiece);
                        }
                    } else if (userSpeakingRef.current) {
                        console.log('User speaking changed to false (empty transcript)');
                        userSpeakingRef.current = false;
                        
                        // Set a timer when user stops speaking
                        if (silenceTimer) clearTimeout(silenceTimer);
                        silenceTimer = setTimeout(() => {
                            if (!userSpeakingRef.current) {
                                communicateWithServer(transcriptRef.current);
                            }
                        }, 2000);
                    }
                }
            };

            socket.onclose = () => console.log('Deepgram WebSocket closed');
            socket.onerror = (error) => console.error('Deepgram WebSocket error:', error);
        } catch (error) {
            console.error('Error initializing Deepgram:', error);
        }
    };

    const communicateWithServer = async (transcriptToSend = '') => {
        setServerCallCount(prevCount => prevCount + 1);
        document.querySelector('.user-video-wrapper').classList.remove('mic-active');


        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive' && isBusiness) {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.onstop = async () => {
                const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
                recordedChunksRef.current = [];
    
                // Initialize storage
                const storage = getStorage(app);
    
                // Upload the video to Firebase Storage
                const storageRef = ref(storage, `interviews/${uniqueIdRef.current}/${Date.now()}.webm`);
                await uploadBytes(storageRef, blob);
                const videoURL = await getDownloadURL(storageRef);
                console.log("Video uploaded to:", videoURL);
    
                // Store the video link in Firestore
                let docRef;
                let docSnapshot;
                let videos = [];
    
                console.log(extractedUidRef.current)
                console.log(jobPathRef.current)
                console.log(uniqueIdRef.current)
                const jobPathWithSpaces = jobPathRef.current.replace(/-/g, ' ');
                docRef = doc(db, `businesses/${extractedUidRef.current}/${jobPathWithSpaces}/interviewees`);
    
                try {
                    docSnapshot = await getDoc(docRef);
                    if (docSnapshot.exists()) {
                        const docData = docSnapshot.data();
                        if (docData[uniqueIdRef.current] && Array.isArray(docData[uniqueIdRef.current].videos)) {
                            videos = docData[uniqueIdRef.current].videos;
                        }
                    } else {
                        await setDoc(docRef, { [uniqueIdRef.current]: { videos: [] } }, { merge: true });
                    }
                    videos.push(videoURL);
                    await updateDoc(docRef, {
                        [`${uniqueIdRef.current}.videos`]: videos
                    });
                    console.log("Video link stored in Firestore");
                } catch (error) {
                    console.error('Error updating Firestore document:', error);
                }
            };
        }

        //console.log("Communicating with server", transcriptToSend ? `Transcript: ${transcriptToSend}` : 'Initial request');
        if (transcriptToSend.trim() === '' && !interviewStarted) {
            console.log('Sending initial request to server.');
        } else {
            console.log('Sending transcript to server:', transcriptToSend);
        }


        try {
            let path = ''; 
            if (isBusiness) {
                path = `https://fractalflaskapp-5c91849aadae.herokuapp.com/interview/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}`;
            } else {
                path = `https://fractalflaskapp-5c91849aadae.herokuapp.com/${currentUser.uid}/${jobTitle}/interview`;
            }

            const bodyContent = {
                chat_log: chat_log,
                topics_list: topics_list,
                queue: queue,
                follow_up_count: follow_up_count,
                current_topic_log: current_topic_log,
                response: transcriptToSend,
            };

            const response = await fetch(path, {
                method: 'POST',
                headers: {
                    'X-React-App': 'true',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
            });

            if (response.ok) {
                console.log('Response received from server.');
                const data = await response.json();

                if (data.status === 'Interview completed') {
                    console.log('Interview completed');
                    handleEndInterview();
                    return;
                }
                if (data.status === 'Generating feedback') {
                    chat_log = data.chat_log;
                    console.log("Generating feedback");
                    setShowLoadingPopup(true);
                    if (isBusiness) {
                        speak("Thank you for interviewing! A recruiter will be in touch with you shortly.");
                    } else {
                        speak("Thank you for interviewing. You can review your scores as soon as the interview is finished processing your results.");
                    }

                    // Release camera and audio resources
                    releaseMediaResources();

                    const feedbackPath = isBusiness 
                        ? `https://fractalflaskapp-5c91849aadae.herokuapp.com/generate-feedback/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}`
                        : `https://fractalflaskapp-5c91849aadae.herokuapp.com/${currentUser.uid}/${jobTitle}/generate-feedback`;
     
                    try {
                        const response2 = await fetch(feedbackPath, {
                            method: 'POST',
                            headers: {
                                'X-React-App': 'true',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ chat_log: chat_log })
                        });
    
                        if (response2.ok) {
                            console.log("Feedback generation completed.");
                            const data2 = await response2.json();
                            if (data2.status === 'Interview completed') {
                                interviewCompleted.current = true;
                                console.log("Interview finished.");
                                const pathParts = location.pathname.split('/');
                                const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
                                if (isBusiness && whiteLabel) {
                                    speak("Thank you for interviewing! You can now close this tab.");
                                    history.push('/interviewdone');
                                } 
                                else if (isBusiness){
                                    speak("Thank you for interviewing! A recruiter will be in touch with you shortly.");
                                    history.push('/home');
                                }
                                else {
                                    speak("Thank you for interviewing. You can review your scores here in the practice tab");
                                    history.push('/results-behavioral-interview');
                                }
                            }
                        }
                    } catch {
                        interviewCompleted.current = true;
                        console.log("Caught an error—redirecting to home page");
                        if (isBusiness) {
                            handleEndInterview();
                            history.push('/home');
                        } else {
                            history.push('/results-behavioral-interview');
                        }
                    }
                }
 

                if (data.newChat) {
                    if (await speak(data.newChat)) {
                        //only reset all these variables if speak returns true
                        console.log("successful comms within interview, changing all state vars")
                        chat_log = data.chat_log || [];
                        topics_list = data.topics_list || [];
                        queue = data.queue || [];
                        follow_up_count = data.follow_up_count || 0;
                        current_topic_log = data.current_topic_log || [];
                    }
                    //console.log(current_topic_log);
                }
                else{
                    console.log("no new chat from the data, going to call the function again")
                    //console.log("curr transcript was " + transcriptRef.current)
                    console.log(data.status)

                    failedServerCalls ++;
                    if (failedServerCalls >= 3){
                        handleEndInterview(false);
                    }
                }
            } else {
                console.error('Failed to communicate with server. response.ok was false');
            }
        } catch (error) {
            console.error('Error communicating with server:', error);
        }
    };

    const speak = async (text) => {
        console.log("AI preparing to speak");
        if (interviewCompleted.current) {
            console.log("Interview completed, AI won't speak");
            return false;
        }

        if (userSpeakingRef.current) {
            console.log('User is speaking, AI will not call voice model');
            return false;
        }

        try {
            const formData = JSON.stringify({
                model: 'tts-1',
                voice: 'nova',
                input: text,
            });

            const response = await fetch('https://api.openai.com/v1/audio/speech', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer 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`,
                    'Content-Type': 'application/json',
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const arrayBuffer = await response.arrayBuffer();
            const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
            const blobURL = URL.createObjectURL(blob);
            const audio = new Audio(blobURL);
            aiAudioRef.current = audio;

            audio.playbackRate = voiceSpeed;

            // Decrement the serverCallCount right before speaking
            setServerCallCount(prevCount => prevCount - 1);

            if (userSpeakingRef.current || serverCallCount > 0) {
                console.log('User started speaking or server request is pending, AI will cancel the speech call');
                return false;
            }

            console.log('AI speaking changed to true, speaking beginning here');
            aiSpeakingRef.current = true;
            setAiSpeaking(true);

            transcriptRef.current = ''; //only reset the transcript once they begin speaking
            clearInterval(timerRef.current);
            setTimeLeft(thistimeLimit.current);
            
            audio.play();

            audio.onended = () => {
                console.log('AI finished speaking');
                console.log('AI speaking changed to false');
                aiSpeakingRef.current = false;
                setAiSpeaking(false);
                aiAudioRef.current = null;
                transcriptRef.current = ''; //resetting transcript for redundancy

                if (videoRef.current && videoRef.current.srcObject && isBusiness) {
                    const options = { mimeType: 'video/webm' };
                    mediaRecorderRef.current = new MediaRecorder(videoRef.current.srcObject, options);
                    mediaRecorderRef.current.ondataavailable = (event) => {
                        if (event.data.size > 0) {
                            recordedChunksRef.current.push(event.data);
                        }
                    };
                    mediaRecorderRef.current.start();
                    console.log("Video recording started");
                }

                // Start the timer when the user begins speaking after AI finishes
                if (timerRef.current) clearInterval(timerRef.current);
                timerRef.current = setInterval(() => {
                    setTimeLeft((prevTime) => {
                        if (prevTime <= 1) {
                            clearInterval(timerRef.current);
                            if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
                                mediaRecorderRef.current.stop();  // Stop recording when time runs out
                            }
                            setTimeLeft(thistimeLimit.current);  // Reset the timer
                            communicateWithServer(transcriptRef.current);  // Send the transcript to the server
                            return 0;
                        }
                        return prevTime - 1;
                    });
                }, 1000);
                
                const userVideoWrapper = document.querySelector('.user-video-wrapper');
                if (userVideoWrapper) {
                    userVideoWrapper.classList.add('mic-active');
                }
            };
            return true;
        } catch (error) {
            console.error('Error during AI speech:', error);
            console.log('AI speaking changed to false (due to error)');
            aiSpeakingRef.current = false;
            setAiSpeaking(false);
            return false;
        }
    };

    const handleEndInterview = async (okay = true) => {
        console.log("Ending interview");
        interviewCompleted.current = true;
        if (aiAudioRef.current) {
            aiAudioRef.current.pause();
            aiAudioRef.current = null;
            setAiSpeaking(false);
        }
        chat_log = [];
        topics_list = [];
        prev_newChat = "";
        queue = [];
        outBrowserCount = 0;
        follow_up_count = 0;

        if (!okay) {
            try {
                const db = getFirestore();
                const interviewRef = doc(db, 'businesses', extractedUidRef.current, jobPathTitleRef.current, 'interviewees', uniqueIdRef.current);
                await updateDoc(interviewRef, {
                    interviewSuccess: false
                });
                console.log("Interview success status updated in Firebase");
            } catch (error) {
                console.error("Error updating interview success status:", error);
            }
        }

        console.log("Interview ended, navigating away");
        if(isBusiness){
            history.push('/home');
        } else {
            history.push('/practice');
        }
    };

    const handleTimeOutOrSpacebarPress = () => {
        console.log("handleTimeOutOrSpacebarPress was called");
        console.log("Sending transcript to server");
        const transcript = recognitionRef.current.latestTranscript;
        console.log("latest transcript: " + transcript);
        communicateWithServer(transcript);
        clearInterval(timerRef.current);
        setTimeLeft(90);
    };
  
    const saveChatLogToFile = () => {
        console.log("saveChatLogToFile was called");
        const jsonString = JSON.stringify(chat_log, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `chat_log_${Date.now()}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const checkInterviewValidity = async (uid, jobPath, uniqueId) => {
        console.log("checkInterviewValidity was called");
        const db = getFirestore();
        const jobPathWithSpaces = jobPath.replace(/-/g, ' ');

        const userRef = doc(db, 'businesses', uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
            const jobRef = doc(db, 'businesses', uid, jobPathWithSpaces, 'interviewees');
            const jobSnapshot = await getDoc(jobRef);

            if (jobSnapshot.exists() && jobSnapshot.data()[uniqueId]) {
                const intervieweeData = jobSnapshot.data()[uniqueId];
                if (intervieweeData.scores) {
                    console.log("Interviewee exists but has scores, invalid interview.");
                    return false;
                }
                console.log("interviewee exists");
                return true;
            }
        }
        console.log("interviewee does not exist");
        return false;
    };

    const scrollToBottom = () => {
        console.log("scrollToBottom was called");
        setTimeout(() => {
            if (questionsColumnRef.current) {
                questionsColumnRef.current.scrollTop = questionsColumnRef.current.scrollHeight;
            }
        }, 0);
    };

    // Add this new function to handle releasing media resources
    const releaseMediaResources = () => {
        console.log("Releasing camera and audio resources");
        
        // Stop the video stream
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        }

        // Stop the audio stream (Deepgram)
        if (deepgramSocketRef.current) {
            deepgramSocketRef.current.close();
        }

        // Stop the MediaRecorder
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
    };

    useEffect(() => {
        console.log("Component mounted or interviewStarted/isBusiness changed");
        initializeVideo();

        const handleSpacebarPress = async (e) => {
            if (e.key === ' ' && !e.repeat && !interviewStarted) {
                e.preventDefault();
                await initializeInterview();
            }
        };

        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        document.addEventListener('keydown', handleSpacebarPress);

        // Hide navbar
        const navbar = document.querySelector('.menu-bar');
        if (navbar) {
            navbar.classList.add('hide');
        }

        return () => {
            console.log("Component unmounting, cleaning up resources");
            document.removeEventListener('keydown', handleSpacebarPress);
            
            // Use the new function to release media resources
            releaseMediaResources();

            // Show navbar again
            const navbar = document.querySelector('.menu-bar');
            if (navbar) {
                navbar.classList.remove('hide');
            }
        };
    }, [interviewStarted, isBusiness]);

    return (
        <div className="interview-container">
            {!interviewStarted && (
                <div className="start-prompt">
                    Press Spacebar to Get Started
                </div>
            )}
            {showLoadingPopup && (
                <div className="loading-popup">
                    <div className="loading-icon"></div>
                    <p>Ending interview...</p>
                </div>
            )}
            <div className="int-video-container">
                <div className={`thinking-dots ${aiSpeaking ? 'ai-speaking' : ''} ${!isRecognizing && !aiSpeaking && interviewStarted ? 'blinking' : ''}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="user-video-wrapper">
                    <video autoPlay playsInline muted ref={videoRef} className="user-video"></video>
                </div>
                <div className="timer-container">
                    <div className="timer-text">{`${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? '0' : ''}${timeLeft % 60}`}</div>
                </div>
            </div>
            <div className="timer-bar">
                <div
                    className="timer-bar-progress"
                    style={{ width: `${(timeLeft / 90) * 100}%` }}
                ></div>
            </div>
            {!isBusiness && (
                <button className="end-interview-button" onClick={handleEndInterview}>
                    <i className="fas fa-phone-alt" style={{ transform: 'rotate(225deg)', color: 'white' }}></i>
                </button>
            )}
        </div>
    );
};

export default Interview;