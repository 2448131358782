import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useAuth } from './authContext';
import { useHistory } from 'react-router-dom';
import { app } from './index';
import './results_page.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF19AB'];

const ResultsPage = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
    const [results, setResults] = useState(null);

    useEffect(() => {
        const fetchResults = async () => {
            const db = getFirestore(app);
            const uid = currentUser.uid;

            // Query to get the most recent interview
            const interviewsRef = collection(db, 'users', uid, 'interviews');
            const q = query(interviewsRef, orderBy('submission_time', 'desc'), limit(1));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const recentInterview = querySnapshot.docs[0].data();
                setResults(recentInterview);
            } else {
                console.log('No interviews found!');
            }
        };

        if (currentUser) {
            fetchResults();
        }
    }, [currentUser]);

    return (
        <div className="results-container">
            {results ? (
                <>
                    <div className="scores-container">
                        {Object.keys(results.scores).map((key, index) => (
                            <div key={index} className="score-item">
                                <p className="score-label" style={{ color: COLORS[index % COLORS.length] }}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                </p>
                                <div className="score-circle" style={{ backgroundColor: COLORS[index % COLORS.length] }}>
                                    <p className="score-value">{results.scores[key]}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="feedback-container">
                        {Object.keys(results.scores).map((key, index) => (
                            <div key={index} className="feedback-item">
                                <h3 style={{ fontWeight: 'bold', color: COLORS[index % COLORS.length] }}>
                                    {key.charAt(0).toUpperCase() + key.slice(1)} Feedback
                                </h3>
                                <p>{results.feedback[key]}</p>
                            </div>
                        ))}
                        <button
                            className="results-next-button"
                            onClick={() => history.push('/practice')}
                        >
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <p>Loading results...</p>
            )}
        </div>
    );
};

export default ResultsPage;
