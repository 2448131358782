import React, { useState, useEffect } from 'react';
import './AddJobPopup.css'; 
import PreviewPopup from './PreviewPopup'; // Import the new PreviewPopup component

const AddJobPopup = ({ isOpen, onClose, onSubmit, editData, existingJobTitles }) => {
  const [jobTitle, setJobTitle] = useState('');
  const [timeLimit, setTimeLimit] = useState('1:30');
  const [jobDescription, setJobDescription] = useState('');
  const [scoringCriteria, setScoringCriteria] = useState([
    { skill: 'Technical', weighting: 20 },
    { skill: 'Communication', weighting: 20 },
    { skill: 'Leadership', weighting: 20 },
    { skill: 'Social', weighting: 20 },
    { skill: 'Grit', weighting: 20 }
  ]);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    if (editData) {
      setJobTitle(editData.jobTitle);
      setJobDescription(editData.jobDescription);
      setScoringCriteria(editData.personalityTraits);
      setTimeLimit(editData.timeLimit ? editData.timeLimit : '1:30'); // Set timeLimit based on editData.timeLimit or default to '1:30'
    } else {
      setJobTitle('');
      setJobDescription('');
      setScoringCriteria([
        { skill: 'Technical', weighting: 20 },
        { skill: 'Communication', weighting: 20 },
        { skill: 'Leadership', weighting: 20 },
        { skill: 'Social', weighting: 20 },
        { skill: 'Grit', weighting: 20 }
      ]);
      setTimeLimit('1:30'); // Default to '1:30' if not in edit mode
    }
  }, [editData]);  

  const handleCriteriaChange = (index, field, value) => {
    const updatedCriteria = [...scoringCriteria];
    if(field === 'weighting'){
      value = parseInt(value);
    }
    if (field === 'skill') {
      value = value.replace(/ /g, '-');
    }
    updatedCriteria[index][field] = value;
    setScoringCriteria(updatedCriteria);
  };

  const handleNext = () => {
    if (jobTitle.trim() === '' || jobDescription.trim() === '') {
      alert('Please fill out all fields');
      return;
    }

    if (!editData && existingJobTitles.includes(jobTitle)) {
      alert('A job with this title already exists. Please choose a different title.');
      return;
    }

    const totalWeighting = scoringCriteria.reduce((total, criteria) => total + parseFloat(criteria.weighting), 0);
    if (totalWeighting !== 100) {
      alert('The total weighting must add up to 100%');
      return;
    }
  
    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className="popup-heading">{editData ? 'Edit Job Details' : 'Add Job Details'}</h2>
        <form>
          <div className="job-title-time-container">
            <input
              type="text"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder="Enter Job Title"
              className="job-title-input"
              disabled={!!editData} // Disable editing if in edit mode
            />
            <div className="time-input-container">
            <h3 className="popup-heading-time">Time Limit per Response:</h3>
            <div className="time-input-wrapper">
              <input
                type="text"
                value={timeLimit}
                onChange={(e) => {
                  let value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                  if (value.length > 3) {
                    value = value.slice(-3); // Limit to the last 3 digits
                  }

                  // Format the input to always have a colon in the correct place
                  if (value.length <= 2) {
                    value = value.padStart(2, '0'); // Pad with zeroes if less than 2 digits
                    value = '0:' + value; // Prefix with 0: for single digits
                  } else {
                    value = value.slice(0, value.length - 2) + ':' + value.slice(-2); // Format as MM:SS
                  }

                  setTimeLimit(value);
                }}
                placeholder="0:00"
                className="time-limit-input"
              />
              <span className="time-unit">mins</span>
            </div>
          </div>
          </div>
          <textarea
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="Paste Job Description"
            className="job-description-input"
          />
          <h3 className="popup-heading">Add Scoring Criteria</h3>
          <div className="scoring-criteria-header">
            <span className="label-text">Skill</span>
            <span className="label-text">Weight Percentage</span>
          </div>
          {scoringCriteria.map((criteria, index) => (
            <div className="skill-row" key={index}>
              <input
                type="text"
                value={criteria.skill}
                onChange={(e) => handleCriteriaChange(index, 'skill', e.target.value)}
                className="skill-input"
                disabled={!!editData} // Disable editing if in edit mode
              />
              <input
                type="number"
                value={criteria.weighting}
                onChange={(e) => handleCriteriaChange(index, 'weighting', e.target.value)}
                className="weighting-input"
              />
            </div>
          ))}
          <div className="popup-buttons">
            <button type="button" className="close-button" onClick={onClose}>Close</button>
            <button type="button" className="next-button" onClick={handleNext}>Next</button>
          </div>
        </form>
      </div>
      <PreviewPopup
        isOpen={isPreviewOpen}
        onClose={handlePreviewClose}
        jobTitle={jobTitle}
        timeLimit={timeLimit}
        jobDescription={jobDescription}
        scoringCriteria={scoringCriteria}
        customQuestions={editData ? editData.customQuestions : []}
        aiQuestions={editData ? Array(editData.aiQuestions).fill('AI Question') : Array(3).fill('AI Question')}
        onSubmit={onSubmit}
        isEditing={editData}
      />
    </div>
  );
};

export default AddJobPopup;