import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './uploadresume.css';
import { useAuth } from './authContext';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from './index';

const GetReady = () => {
    const { currentUser } = useAuth();
    const location = useLocation();
    const jobTitle = location.state ? location.state.jobTitle : '';
    const history = useHistory();
    const [permissionsGranted, setPermissionsGranted] = useState(false);
    const [isBusiness, setIsBusiness] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isValidInterview, setIsValidInterview] = useState(false);
    const extractedUidRef = useRef(null);
    const jobPathRef = useRef(null);
    const uniqueIdRef = useRef(null);
    const [fadeOut, setFadeOut] = useState(false);
    const [cameraPermission, setCameraPermission] = useState(false);
    const [microphonePermission, setMicrophonePermission] = useState(false);
    const [allPermissionsGranted, setAllPermissionsGranted] = useState(false);
    const topicsList = location.state ? location.state.topicsList : [];
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const [averageVolume, setAverageVolume] = useState(0);
    const squares = Array(5).fill(0);

    const checkInterviewValidity = async (uid, jobPath, uniqueId) => {
        const db = getFirestore();
        const jobPathWithSpaces = jobPath.replace(/-/g, ' ');

        const userRef = doc(db, 'businesses', uid);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) {
            const jobRef = doc(db, 'businesses', uid, jobPathWithSpaces, 'interviewees');
            const jobSnapshot = await getDoc(jobRef);

            if (jobSnapshot.exists() && jobSnapshot.data()[uniqueId]) {
                const intervieweeData = jobSnapshot.data()[uniqueId];
                if (intervieweeData.scores) {
                    console.log("Interviewee exists but has scores, invalid interview.");
                    return false;
                }
                if(jobSnapshot.exists() && intervieweeData.interviewStarted == true){
                    console.log("Interviewee exists but has already started interview, invalid interview.");
                    return false;
                }
                console.log("interviewee exists");
                return true;
            }
        }
        console.log("interviewee does not exist");
        return false;
    };

    const getColorForVolume = (volume) => {
        if (volume < 20) return 'lightgreen'; // Green for volumes < 20
        if (volume < 40) return 'yellow'; // Yellow for volumes 20-39
        if (volume < 60) return 'orange'; // Red for volumes 40-59
        return 'red'; // Dark red for volumes >= 60
    };    

    useEffect(() => {
        const validateInterview = async () => {
            const pathParts = location.pathname.split('/');
            const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";

            if (pathParts.length === 5 || (pathParts.length === 6 && whiteLabel)) {
                const extractedUid = pathParts[2];
                const jobPath = pathParts[3];
                const uniqueId = pathParts[4];
                extractedUidRef.current = extractedUid;
                jobPathRef.current = jobPath;
                uniqueIdRef.current = uniqueId;
                setIsBusiness(true);

                if (extractedUid && jobPath && uniqueId) {
                    const isValid = await checkInterviewValidity(extractedUid, jobPath, uniqueId);
                    setIsValidInterview(isValid);
                } else {
                    setIsValidInterview(false);
                }
            } else {
                setIsValidInterview(true);
            }
            setLoading(false);
        };

        validateInterview();
    }, [location.pathname]);

    const visualizeAudio = () => {
        const analyser = analyserRef.current;
        const dataArray = new Uint8Array(analyser.fftSize);
        const volumeHistory = [];
        const historyLength = 50; // Number of samples to average over (adjust as needed)

        const updateAudioLevels = () => {
            analyser.getByteFrequencyData(dataArray);
            const currentVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
            
            volumeHistory.push(currentVolume);
            if (volumeHistory.length > historyLength) {
                volumeHistory.shift(); // Remove oldest sample if we exceed historyLength
            }

            const averageVolume = volumeHistory.reduce((sum, volume) => sum + volume, 0) / volumeHistory.length;
            setAverageVolume(averageVolume); // Update average volume state

            requestAnimationFrame(updateAudioLevels);
        };

        updateAudioLevels();
    };

    const checkPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
            setMicrophonePermission(true);

            audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            const source = audioContextRef.current.createMediaStreamSource(stream);
            analyserRef.current = audioContextRef.current.createAnalyser();
            analyserRef.current.fftSize = 32;

            source.connect(analyserRef.current);

            visualizeAudio();
        } catch (error) {
            console.error('Error checking permissions:', error);
        }
    };

    const togglePermission = async (type) => {
        switch (type) {
            case 'camera':
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                    setCameraPermission(true);
                    stream.getTracks().forEach(track => track.stop());
                } catch (error) {
                    console.error('Error toggling camera permission:', error);
                    setCameraPermission(false);
                }
                break;
            case 'microphone':
                try {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    setMicrophonePermission(true);
                    stream.getTracks().forEach(track => track.stop());
                } catch (error) {
                    console.error('Error toggling microphone permission:', error);
                    setMicrophonePermission(false);
                }
                break;
        }
    };

    useEffect(() => {
        checkPermissions();
    }, []);

    useEffect(() => {
        setAllPermissionsGranted(cameraPermission && microphonePermission);
    }, [cameraPermission, microphonePermission]);

    const handleApprove = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            if (stream) {
                setPermissionsGranted(true);
            }
        } catch (error) {
            console.error('Error accessing camera and microphone:', error);
        }
    };

    const handleNext = () => {
        if (averageVolume > 40) {
            alert("You can only proceed to the interview once you are in a quieter environment.");
            return;
        }

        if (allPermissionsGranted) {
            console.log("All permissions granted")
            let interviewPath = '/interview';
            if (location.pathname.includes('/getready/coding')) {
                interviewPath = '/codinginterview';
            } else if (isBusiness) {
                const pathParts = location.pathname.split('/');
                const whiteLabel = pathParts[pathParts.length - 1] === "ai-interview";
                interviewPath = `/interview/${extractedUidRef.current}/${jobPathRef.current}/${uniqueIdRef.current}${whiteLabel ? '/ai-interview' : ''}`;
            }
    
            history.push({
                pathname: interviewPath,
                state: { 
                    jobTitle: jobTitle, 
                    isBusiness: isBusiness,
                    topics_list: topicsList
                }
            });
        } else {
            console.error("Not all permissions have been granted.");
            alert("Please grant all permissions before continuing.");
        }
    };

    // Remove the useEffect for space key handling

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isValidInterview) {
        return <div className="upload-resume-container"><h1 className="upload-resume-title">Interview has expired</h1></div>;
    }

    return (
        <div className="upload-resume-container">
            <h1 className={`upload-resume-title ${fadeOut ? 'fade-out' : ''}`}>
                {"We're excited to learn more about you!".split(" ").map((word, wordIndex) => (
                <span key={wordIndex} className="fade-in-char" style={{ animationDelay: `${wordIndex * 0.1}s` }}>
                    {word}&nbsp;
                </span>
                ))}
            </h1>
            <div className="audio-visualization">
                {squares.map((_, index) => (
                    <div 
                        key={index} 
                        className="audio-square" 
                        style={{ backgroundColor: getColorForVolume(averageVolume) }}
                    />
                ))}
            </div>
            <div className="status-message">
                {!allPermissionsGranted ? (
                    <div className="visible">
                        Please grant all permissions before continuing.
                    </div>
                ) : averageVolume > 40 ? (
                    <div className="visible">
                        Please move to a quieter area.
                    </div>
                ) : null}
            </div>
            <p style={{ marginTop: '50px', color: 'white', fontWeight: 'normal', maxWidth: '800px' }}>
                You are entering an AI Interview enabled by voice. Wait for the AI to finish speaking before you start, and give it a few seconds to give your next question. You will be recorded in this interview, so don't proceed unless you are comfortable with being recorded. Good luck!
            </p>

            <div className="permissions-container">
                <div className="permission-toggle">
                    <span className="toggle-label">Camera</span>
                    <label className="toggle-switch">
                        <input 
                            type="checkbox" 
                            checked={cameraPermission} 
                            onChange={() => togglePermission('camera')}
                        />
                        <span className="slider"></span>
                    </label>
                </div>
                <div className="permission-toggle">
                    <span className="toggle-label">Microphone</span>
                    <label className="toggle-switch">
                        <input 
                            type="checkbox" 
                            checked={microphonePermission} 
                            onChange={() => togglePermission('microphone')}
                        />
                        <span className="slider"></span>
                    </label>
                </div>
            </div>

            <button 
                className="getready-next-button" 
                onClick={handleNext}
                disabled={!allPermissionsGranted || averageVolume > 40}
            >
                Next
            </button>
        </div>
    );    
};

export default GetReady;